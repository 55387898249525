<script setup>
const props = defineProps(['documents']);

const documentTypes = computed(() => {
  // find all types and remove duplicates
  if (props.documents) {
    let types = props.documents.map((item) => (item.type ? item.type : null));
    if (types[0] == null) {
      return null;
    } else {
      return types
        .filter((item, index) => types.indexOf(item) === index)
        .sort()
        .reverse();
    }
  }
});
</script>

<template>
  <div>
    <div v-if="props.documents">
      <div v-for="fileType in documentTypes" class="mt-5">
        <h2 class="heading heading--2 heading--thin">{{ $t('global.documents.' + fileType) }}</h2>

        <template v-if="fileType === 'basic'">
          <LinkList class="mt-3">
            <LinkListItem v-for="(doc, key) in documents.filter((item) => item.type === fileType)" :key="key" :link="doc.file.url">
              {{ doc.name }}
            </LinkListItem>
          </LinkList>
        </template>
        <template v-else>
          <FileGrid :data="documents.filter((item) => item.type === type)" class="mt-3" />
        </template>
      </div>
    </div>
  </div>
</template>
